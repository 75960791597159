import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import Heading from "../components/Heading";

export default class Apply extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet htmlAttributes={{ lang: "en" }}>
          <title>Apply online</title>
          <meta property="og:title" content="Apply online" />
          <meta
            name="description"
            content={"Leeds based child care for children 3 months to 5 years."}
          />
          <meta
            property="og:description"
            content={"Leeds based child care for children 3 months to 5 years."}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Apply online" />
          <meta
            name="twitter:description"
            content={"Leeds based child care for children 3 months to 5 years."}
          />
          <link
            rel="icon"
            type="/images/favicon.ico"
            href="/images/favicon.ico"
            sizes="16x16"
          />
        </Helmet>
        <div className="max-w-4xl mx-auto pt-40 px-6">
          <Heading className={`rfs:text-6xl mb-6`} colour="blue">
            Apply online
          </Heading>
        </div>
        <form
          netlify
          method="POST"
          action="/contact-us/thank-you"
          name="job-application"
          data-netlify="true"
          netlify-honeypot="bot-field"
          className="max-w-4xl mx-auto py-12 px-6"
        >
          <div className="mt-6 grid grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-6">
              <label htmlFor="position_applied_for" className="label-default">
                Position applied for:
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="position_applied_for"
                  id="position_applied_for"
                  required
                  placeholder="Position applied for"
                  className="input-default"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="prefered_location" className="label-default">
                Prefered Location:
              </label>
              <div className="mt-1">
                <select
                  type="select"
                  name="prefered_location"
                  id="prefered_location"
                  required
                  placeholder="Prefered location"
                  className="input-default"
                >
                    <option value="bramley">Bramley</option>
                    <option value="morley">Morley</option>
                    <option value="gipton">Gipton</option>
                    <option value="flexible">Flexible</option>
                  </select>
              </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="hours" className="label-default mb-3">
                What hours are you available to work?
              </label>
                <div className="mt-3">
                    <div className="flex mb-3">                    
                      <input
                          type="checkbox"
                          name="hours"
                          id="hours"                   
                          className="input-default w-2"
                          value="Full time"
                        />
                        <label className="ml-3">Full time</label><br/>
                      </div>
                      <div className="flex">   
                        <input
                          type="checkbox"
                          name="hours"
                          id="hours"
                          className="input-default w-2"
                          value="Part time"
                        />
                        <label className="ml-3">Part time</label><br/>
                    </div>
                 </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="dbs_registered" className="label-default mb-3">
                Are you registered for the DBS update service? 
              </label>
                <div className="mt-3">
                    <div className="flex mb-3">                    
                      <input
                          type="radio"
                          name="dbs_registered"
                          id="dbs_registered"                   
                          className="input-default w-2"
                          value="Yes"
                        />
                        <label className="ml-3">Yes, I am registered for the DBS update service.</label><br/>
                      </div>
                      <div className="flex">   
                        <input
                          type="radio"
                          name="dbs_registered"
                          id="dbs_registered"
                          className="input-default w-2"
                          value="No"
                        />
                        <label className="ml-3">No, I am not registered for the DBS update service.</label><br/>
                    </div>
                 </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="first_aid_certificate" className="label-default mb-3">
                Do you hold a valid paediatric first aid certificate?
              </label>
                <div className="mt-3">
                    <div className="flex mb-3">                    
                      <input
                          type="radio"
                          name="first_aid_certificate"
                          id="first_aid_certificate"
                          className="input-default w-2"
                          value="Yes"
                        />
                        <label className="ml-3">Yes, I hold a valid paediatric first aid certificate.</label><br/>
                    </div>
                    <div className="flex"> 
                        <input
                          type="radio"
                          name="first_aid_certificate"
                          id="first_aid_certificate"                        
                          className="input-default w-2"
                          value="No"
                        />
                        <label className="ml-3">No, I do not hold a valid paediatric first aid certificate.</label><br/>
                    </div>
                 </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="full_name" className="label-default">
                Full name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="full_name"
                  id="full_name"
                  autoComplete="given-name"
                  required
                  placeholder="Full name"
                  className="input-default"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="email" className="label-default">
                Date of birth
              </label>
              <div className="mt-1">
                <input
                  type="date"
                  name="date_of_birth"
                  id="date_of_birth"
                  required
                  placeholder="Date of birth"
                  className="input-default"
                />
              </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="address" className="label-default">
                Address
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="address"
                  id="address"
                  required
                  placeholder="Address"
                  className="input-default"
                />
              </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="place_of_birth" className="label-default">
                Place of birth
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="place_of_birth"
                  id="place_of_birth"
                  required
                  placeholder="Place of birth"
                  className="input-default"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="email" className="label-default">
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  required
                  placeholder="Email address"
                  className="input-default"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <div className="flex flex-row justify-between">
                <label htmlFor="phone" className="label-default">
                  Phone
                </label>
              </div>
              <div className="mt-1">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  required
                  placeholder="Phone"
                  className="input-default"
                />
              </div>
            </div>
            <div className="sm:col-span-6 mb-5">
              <div className="flex flex-col justify-between">
                <label htmlFor="qualifications" className="label-default">
                  Qualifications/Training
                </label>
                <p className="text-sm text-gray-400 mb-1 max-w-prose">
                  Please list below any qualifications gained and / or training
                  courses undertaken, which are relevant to the post for which
                  you have applied. (Please provide copies of any certificates).
                </p>
              </div>
              <div className="mt-1">
                <textarea
                  type="text"
                  name="qualifications"
                  id="qualifications"
                  required
                  rows={5}
                  className="input-default"
                  defaultValue={""}
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-6 mt-3">
            <div className="flex flex-col justify-between">
              <label htmlFor="employment" className="label-default">
                Employment/Work experience
              </label>
              <p className="text-sm text-gray-400 mb-1 max-w-prose">
                Please include details of any previous employment or work
                experience placements. Please start with the most recent,
                including dates and reason for leaving.
              </p>
            </div>
            <div className="mt-1">
              <textarea
                type="text"
                name="employment"
                id="employment"
                required
                rows={5}
                className="input-default"
                defaultValue={""}
              />
            </div>
          </div>
          <div className="sm:col-span-6 mt-6">
            <div className="flex flex-col justify-between">
              <label htmlFor="reference-1" className="label-default">
                Reference 1
              </label>
              <p className="text-sm text-gray-400 mb-1 max-w-prose">
                (Name, Relationship in which known, Company, Contact Number, Email)
              </p>
            </div>
            <div className="mt-1">
              <textarea
                type="text"
                name="reference-1"
                id="reference-1"
                required
                rows={5}
                className="input-default"
                defaultValue={""}
              />
            </div>
          </div>
          <div className="sm:col-span-6 mt-6">
            <div className="flex flex-col justify-between">
              <label htmlFor="reference-2" className="label-default">
                Reference 2
              </label>
              <p className="text-sm text-gray-400 mb-1 max-w-prose">
                (Name, Relationship in which known, Company, Contact Number, Email)
              </p>
            </div>
            <div className="mt-1">
              <textarea
                type="text"
                name="reference-2"
                id="reference-2"
                required
                rows={5}
                className="input-default"
                defaultValue={""}
              />
            </div>
          </div>
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="job-application" />
          <button type="submit" className="btn btn-green mt-6">
            Submit
          </button>
        </form>
      </Layout>
    );
  }
}
